// ** @jsx jsx *
import { jsx, Text, Alert, Button } from "theme-ui"
import React, { useState, useEffect, useContext } from "react"

import { CartContext, UserContext } from "../components/context/Context"
import { graphql } from "gatsby"
import TotalCount from "../components/TotalCount"

const CarritoPdf = ({data}) => {
const { cart, setCart } = useContext(CartContext)
const  [user, setUser]  = useState()

const allProducts = []
let total = 0
if (cart && cart.length){
    cart.forEach((product)=>{
        product.order.forEach((p)=>{
            allProducts.push(p)
        })
    })
}

useEffect(()=>{
    if(typeof window !== "undefined" && window && window.localStorage){
        setUser(JSON.parse(window.localStorage.userData))
    }
},[])
  return (
    
     
        <div>
       
          <div
            filename="recibo-pdf.pdf"
            sx={{
              backgroundColor: "#ffffff",
              color: "#000000",
              fontFamily: "sans-serif",
              width: 612,
              minHeight: 792,
              padding: 2,
            }}
          >
            <ul>
              <li
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <img
                      sx={{
                        width: "30px",
                        height: "30px",
                        mr: 2,
                      }}
                      src={data.sanitySetting.logo.asset.url}
                      alt={data.sanitySetting.title}
                    />
                    <span>{data.sanitySetting.title}</span>
                  </div>
                  <div>
                      <span>contacto: {data.sanitySetting.whatsapp.phone}</span>
                  </div>
                </div>
                <br />
                <div
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {user && (
                    <>
                      <div
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                        }}
                      >
                        {Object.keys(user).map(key => {
                          return <strong>{key}</strong>
                        })}
                      </div>
                      <div
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {Object.values(user).map(value => {
                          return <span>{value}</span>
                        })}
                      </div>
                    </>
                  )}
                </div>
              </li>
              <hr />
              <hr />
              <li
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  strong: {
                    width: "25%",
                    textAlign: "center",
                  },
                }}
              >
                <strong
                  sx={{
                    textAlign: "initial !important",
                  }}
                >
                  Producto
                </strong>
                <strong>Cantidad</strong>
                <strong>Precio </strong>
                <strong>PreTotal</strong>
              </li>
              <hr />
              {allProducts.map((product, i) => {
                let url
                if (typeof window !== "undefined" && window) {
                  url = `${window.location.origin}/${product.slug}`
                }
                return (
                  <>
                    <li
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        span: {
                          width: "25%",
                          textAlign: "center",
                        },
                      }}
                    >
                      <span
                        sx={{
                          textAlign: "initial !important",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <a href={url}>{product.title}</a>
                        {product.tag && <small>{product.tag}</small>}
                      </span>
                      <span>{` ${product.count} `}</span>
                      <span>{` ${product.price} `}</span>
                      <span>{` ${product.count * product.price} `}</span>
                    </li>
                    <hr />
                  </>
                )
              })}
              <li
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  span: {
                    width: "25%",
                    textAlign: "center",
                  },
                }}
              >
                <span
                  sx={{
                    textAlign: "initial !important",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {" "}
                  <strong>TOTAL</strong>
                </span>
                <span></span>
                <span></span>
                <span>
                  <strong
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      "*": {
                        fontFamily: "sans-serif !important",
                        fontSize: "1.1em",
                        background: "#ffffff ",
                        color: "#000000 ",
                      },
                    }}
                  >
                    <TotalCount />
                  </strong>
                </span>
              </li>
            </ul>
          </div>
        </div>
  
 
  )
}

export default CarritoPdf

export const query = graphql`
query CarritoPdf {
    sanitySetting {
      description
      logo {
        asset {
          url
          fixed(width: 45, height: 45) {
            base64

            width
            height
            src
            srcSet
            srcWebp
            srcSetWebp
          }
          fluid(maxWidth: 150) {
            base64
            srcWebp
            srcSetWebp
            srcSet
            src
            sizes
            aspectRatio
          }
        }
      }
      title
      whatsapp {
        message
        phone
      }

      money
    }
   
  }
`